import Vue from 'vue'
import VueGtm from '@gtm-support/vue2-gtm';
import VueMeta from 'vue-meta'
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import App from './App.vue'
import vueCustomElement from 'vue-custom-element'
import 'document-register-element/build/document-register-element'
import axios from 'axios'
import Paginate from 'vuejs-paginate'
import Pagination from 'vue-pagination-2';

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'


Vue.component('paginate', Paginate);
Vue.component('pagination', Pagination);
Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(vueCustomElement);
Vue.use(VueMeta);

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;

const accountId = document.getElementsByTagName('news-widget')[0].getAttribute("account-id");
const cssId = document.getElementsByTagName('news-widget')[0].getAttribute("css-id");
const gtmId = document.getElementsByTagName('news-widget')[0].getAttribute("gtm-id");


Vue.use(VueGtm, {
    id: gtmId || process.env.VUE_APP_GTA_ID,
    defer: false,
    compatibility: false,
    nonce: '2726c7f26c',
    enabled: true,
    debug: true,
    loadScript: false,
    trackOnNextTick: false,
});

axios.get(`/account/${accountId}/css/${cssId}`).then(data => {

    let css = '';
    if (data && data.data && data.data.status) {
        css = data.data.value.css;
    }

    axios.get(`${process.env.VUE_APP_SELF_HOST}/css/app.css`).then(data => {
        Vue.customElement('news-widget', App, {
            shadow: true,
            shadowCss: `${data.data} ${css}`,
            props: ['prop1',
                'prop2',
                'prop3']
        });
    });
});


/*Vue.customElement('news-widget', App, {
    props: ['prop1',
        'prop2',
        'prop3']
});*/
