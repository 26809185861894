<template>
    <div class="widget-carousel-item">
        <h6>{{data.time}}</h6>
        <p class="header">{{data.text}}</p>
        <b-link @click="onclick(data.link)" class="btn-readMore">Read more</b-link>
    </div>
</template>

<style>
    .header {
        line-height: 1.3em;
        height: 3.9em;
        overflow: hidden;
    }
</style>

<script>

    export default {
        props: {
            data: Object,
        },
        methods: {
            onclick(link) {
                this.$gtm.trackEvent({
                  event: 'View Press Release',
                  category: 'view',
                  action: 'click',
                  label: 'View Press Release',
                  value: this.data.text
                });
                window.open(link, "_self")
            }
        }
    }

</script>
