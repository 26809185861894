<template>
  <div class="news-block">
    <div class="news-stutus">
      <ul>
        <li><i class="far fa-calendar-alt"></i> {{ data.date }}</li>
        <li><i class="far fa-clock"></i> {{ data.time }}</li>
      </ul>
    </div>
    <h4>{{ data.text }}</h4>
    <b-link @click="onclick(data.link)" class="learn-more">Read More</b-link>
  </div>
</template>

<style>

.news-block {
  background-color: #f7f7f7;
  border-radius: 15px;
  margin-bottom: 30px;
  padding: 25px 30px;
  background-repeat: no-repeat;
  background-position: right bottom;
  background-image: url(https://storage.googleapis.com/investably-app.appspot.com/news-bg.png);
}

.news-block:last-child {
  margin-bottom: 0px;
}

.news-block ul {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
  display: flex;
}

.news-block ul li {
  padding-right: 30px;
}

.news-block ul li i {
  color: #b400ff;
  margin-right: 2px;
}

.news-block h4 {
  margin: 10px 0px 15px;
  color: #0e1f2d;
}

.news-block a.learn-more {
  padding: 9px 30px;
}


.news-pesignation {
  padding-top: 30px;
}

.news-pesignation ul {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
  display: flex;
  justify-content: center;
}

.news-pesignation ul li {
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  color: #0e1f2d;
  border-radius: 50%;
}

.news-pesignation ul li:first-child {
  margin-right: 15px;
}

.news-pesignation ul li:last-child {
  margin-left: 5px;
}

.news-pesignation ul li a {
  color: #0e1f2d;
  display: block;
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  border-radius: 50%;
}

.news-pesignation ul li:hover a {
  color: #b400ff;
  text-decoration: none;
}

.news-pesignation ul li.disable, .news-pesignation ul li.disable a {
  color: #d2d5d7;
}

.news-pesignation ul li.active a {
  background: #b100ff; /* Old browsers */
  background: -moz-linear-gradient(top, #b100ff 0%, #ab01fe 49%, #2418ea 50%, #2518ea 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #b100ff 0%, #ab01fe 49%, #2418ea 50%, #2518ea 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #b100ff 0%, #ab01fe 49%, #2418ea 50%, #2518ea 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b100ff', endColorstr='#2518ea', GradientType=0);
  color: #fff;
}

.learn-more, .learn-more-white {
  color: #b200ff;
  border: 2px solid #b200ff;
  padding: 12px 30px;
  border-radius: 30px;
  display: inline-block;
  text-transform: uppercase;
}

.learn-more:hover {
  background: #b200ff;
  text-decoration: none;
  color: #fff;
}

</style>

<script>

export default {
  props: {
    data: Object,
  },
  methods: {
    onclick (link) {
        this.$gtm.trackEvent({
          event: 'View Press Release',
          category: 'view',
          action: 'click',
          label: 'View Press Release',
          value: this.data.text
        });
        window.open(link, "_self")
    }
  }
}

</script>
