<template>
  <div>
    <!--<div class="row">
                <div class="form-group col-12 col-sm-6 col-lg-3">
                    <select class="form-control custom-select">
                        <option selected>All Years</option>
                        <option value="2020">2020</option>
                        <option value="2019">2019</option>
                        <option value="2018">2018</option>
                    </select>
                </div>
                <div class="form-group col-12 col-sm-6 col-lg-3">
                    <select class="form-control custom-select">
                        <option selected>All Releases</option>
                        <option value="2020">Financial Releases</option>
                    </select>
                </div>
            </div>-->
    <div class="row">
      <div class="col-12 mt-4">
        <ContentLoader v-for="index in loaderCount" :key="index"/>
        <Item :data="item" v-for="item in data" :key="item.id"/>
      </div>
    </div>
    <div class="page-navigation">
      <paginate
          :page-count="pageCount"
          :page-range="1"
          :margin-pages="2"
          :click-handler="pageChange"
          :prev-text='leftArrow'
          :next-text='rightArrow'
          :container-class="'pagination'"
          :page-class="'page-item'"
          :page-link-class="'page-link'">
      </paginate>
    </div>
  </div>
</template>


<style>
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #3253dc !important;
  border-color: #3253dc !important;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>

<script>

import axios from 'axios'
import moment from 'moment'
import Item from './Item'
import ContentLoader from "../../content-loader/ContentLoader";

export default {
  props: {
    accountId: {
      type: String
    },
    pageSize: {
      type: Number
    },
    source: {
      type: String
    },
    seo: {
      type: Boolean
    },
    seoBaseUrl: {
      type: String
    }
  },
  name: 'App',
  data() {
    return {
      loaderCount: 4,
      finalPageSize: 10,
      pageNumber: 1,
      pageCount: 0,
      data: [],
      baseUrl: '',
      dateFormat: 'MMM DD, YYYY hh:mma',
    }
  },
  computed: {
    leftArrow() {
      return '<svg\n' +
          '  width="24"\n' +
          '  height="24"\n' +
          '  viewBox="0 0 24 24"\n' +
          '  fill="none"\n' +
          '  xmlns="http://www.w3.org/2000/svg"\n' +
          '>\n' +
          '  <path\n' +
          '    d="M16.2426 6.34317L14.8284 4.92896L7.75739 12L14.8285 19.0711L16.2427 17.6569L10.5858 12L16.2426 6.34317Z"\n' +
          '    fill="currentColor"\n' +
          '  />\n' +
          '</svg>';
    },
    rightArrow() {
      return '<svg\n' +
          '      width="24"\n' +
          '      height="24"\n' +
          '      viewBox="0 0 24 24"\n' +
          '      fill="none"\n' +
          '      xmlns="http://www.w3.org/2000/svg">\n' +
          '    <path\n' +
          '        d="M10.5858 6.34317L12 4.92896L19.0711 12L12 19.0711L10.5858 17.6569L16.2427 12L10.5858 6.34317Z"\n' +
          '        fill="currentColor"\n' +
          '    />\n' +
          '  </svg>';
    }
  },
  components: {
    Item,
    ContentLoader
  },
  methods: {
    pageChange(next) {
      this.data = [];
      this.pageNumber = next;
      this.getData();
    },
    async getData() {
      this.loaderCount = this.finalPageSize;
      let passAccountId = this.accountId;
      let response = await axios.get(`news/list?accountId=${passAccountId}&pageSize=${this.finalPageSize}&pageNumber=${this.pageNumber - 1}&source=${this.source}`);
      const res = response.data;
      if (res && res.status) {
        const settings = res.settings
        this.dateFormat = (settings && settings.pressReleaseTimeFormat) || this.dateFormat
        for (let i = 0; i < res.data.length; i++) {
          const item = res.data[i];
          let link = `${this.baseUrl}?newsId=${item.id}`
          if (this.seo) {
            link = `${this.seoBaseUrl}/${item.titleId}#/?newsId=${item.id}`
          }
          this.data.push({
            link: link,
            time: moment(item.date, 'YYYY-MM-DDTHH:mm:ssZ').format(this.dateFormat),//May 25, 2020 6:20am EDT
            text: item.title
          });
        }
        const pagination = res.pagination;
        const totalRecords = pagination.total;
        this.pageCount = parseInt((totalRecords + this.finalPageSize - 1) / this.finalPageSize);
      }
      this.loaderCount = 0;
    }
  },
  async beforeMount() {
    if (this.pageSize) {
      this.finalPageSize = this.pageSize;
    }
    let response = await axios.get(`news/base-url?accountId=${this.accountId}`);
    const res = response.data;
    if (res && res.status) {
      const val = res.value;
      this.baseUrl = val.baseUrl;
    }
  },
  mounted() {
    this.getData();
  }
}

</script>
