<template>
  <div class="slide-item">
    <a @click="onclick(data.link)" class="tile-thumb-item bg-gray border-radius-20px wow zoomIn" data-wow-delay="0.2s">
      <span class="nav-arrow bi-arrow-right" v-html="rightArrow"></span>
      <h6>{{ data.time }}</h6>
      <h4><strong>{{ data.text }}</strong></h4>
    </a>
  </div>
</template>

<style>
.header {
  line-height: 1.3em;
  height: 3.9em;
  overflow: hidden;
}

.slide-item {
  outline: none;
}

.tile-thumb-item {
  padding: 25px 30px 20px;
  margin: 0 0 0;
  position: relative;
  display: flex;
  flex-direction: column;
  box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, .0);
  color: #61CBC8;
  height: 100%;
}

.tile-thumb-item:hover {
  opacity: 0.7;
  cursor: pointer;
}

.tile-thumb-item:hover {
  box-shadow: inset 0px 0px 20px 0px rgba(0, 0, 0, .1);
  text-decoration: none;
}

.tile-thumb-item .bi-arrow-right {
  margin: 0 0 62px auto;
  transition: all .3s ease;
  left: 0;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  font-size: 35px;
}

.tile-thumb-item:hover .bi-arrow-right {
  left: 5px;
}

.tile-thumb-item h4 {
  font-size: 24px;
  color: #093E52;
}

.tile-thumb-item h6 {
  font-weight: 500;
  margin: 0 0 0;
  padding: 0 0 15px;
  font-size: 16px;
  color: #093E52;
}

.bg-gray {
  background-color: #F9F9F9;
}

.border-radius-20px {
  border-radius: 20px;
}

.bi-arrow-right {
  color: #61CBC8;
}

</style>

<script>

export default {
  props: {
    data: Object,
  },
  computed: {
    rightArrow() {
      return '<svg fill="#61CBC8" height="24" width="24" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" \n' +
          '\t viewBox="0 0 330 330" xml:space="preserve">\n' +
          '<path id="XMLID_27_" d="M15,180h263.787l-49.394,49.394c-5.858,5.857-5.858,15.355,0,21.213C232.322,253.535,236.161,255,240,255\n' +
          '\ts7.678-1.465,10.606-4.394l75-75c5.858-5.857,5.858-15.355,0-21.213l-75-75c-5.857-5.857-15.355-5.857-21.213,0\n' +
          '\tc-5.858,5.857-5.858,15.355,0,21.213L278.787,150H15c-8.284,0-15,6.716-15,15S6.716,180,15,180z"/>\n' +
          '</svg>';
    }
  },
  methods: {
    onclick(link) {
      window.open(link, "_self")
      this.$gtm.trackEvent({
        event: 'View Press Release',
        category: 'view',
        action: 'click',
        label: 'View Press Release',
        value: this.data.text
      });
    }
  }
}

</script>
