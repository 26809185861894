<template>
  <div id="app">
    <div class="">
      <div class="container">
        <ListView5 v-if="!newsDetails && (templateId===5)" :account-id="accountId" :page-size="pageSize"
                   :source="source" :seo="seo" :seo-base-url="seoBaseUrl"/>
        <ListView4 v-if="!newsDetails && (templateId===4)" :account-id="accountId" :page-size="pageSize"
                   :source="source" :seo="seo" :seo-base-url="seoBaseUrl"/>
        <ListView3 v-if="!newsDetails && (templateId===3)" :account-id="accountId" :page-size="pageSize"
                   :source="source" :seo="seo" :seo-base-url="seoBaseUrl"/>
        <NewsListV2 v-if="!newsDetails && (templateId===2)" :account-id="accountId" :page-size="pageSize"
                    :source="source" :seo="seo" :seo-base-url="seoBaseUrl"/>
        <ListView v-if="!newsDetails && (!templateId || templateId===1)" :account-id="accountId"
                  :page-size="pageSize" :source="source" :seo="seo" :seo-base-url="seoBaseUrl"/>
        <NewsDetails v-if="newsDetails" :account-id="accountId" :news-id="passNewsId" :source="source"/>
      </div>
      <div>
        <ListView6 v-if="!newsDetails && (templateId===6)" :account-id="accountId" :page-size="pageSize"
                   :source="source" :seo="seo" :seo-base-url="seoBaseUrl" :autoplay="autoplay"/>
      </div>
    </div>
  </div>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>

<script>

import ListView from '../components/list/template1/ListView'
import NewsDetails from '../components/details/NewsDetails'
import NewsListV2 from '../components/list/template2/NewsListV2'
import ListView3 from '../components/list/template3/ListView3'
import ListView4 from '../components/list/template4/ListView4'
import ListView5 from '../components/list/template5/ListView5'
import ListView6 from '../components/list/template6/NewsListV2.vue'

export default {
  props: {
    accountId: {
      type: String
    },
    newsId: {
      type: String
    },
    newsDetails: {
      type: Boolean
    },
    pageSize: {
      type: Number
    },
    templateId: {
      type: Number
    },
    source: {
      type: String
    },
    seo: {
      type: Boolean
    },
    seoBaseUrl: {
      type: String
    },
    autoplay: {
      type: Boolean
    },
  },
  data() {
    return {
      passNewsId: ''
    }
  },
  components: {
    ListView,
    NewsDetails,
    NewsListV2,
    ListView3,
    ListView4,
    ListView5,
    ListView6
  },
  name: 'App',
  mounted() {
  },
  beforeMount() {
    this.passNewsId = this.newsId
    const urlParams = new URLSearchParams(window.location.search);
    const newsId = urlParams.get('newsId');
    if (newsId) {
      this.passNewsId = newsId
    }
  },
}
</script>
