<template>
  <div>
    <div class="row">
      <div class="col-12 mt-4">
        <ContentLoader v-for="index in loaderCount" :key="index"/>
        <Item :data="item" v-for="item in data" :key="item.id"/>
      </div>
    </div>
    <div class="page-navigation">
      <paginate
          :page-count="pageCount"
          :page-range="1"
          :margin-pages="2"
          :click-handler="pageChange"
          :prev-text='leftArrow'
          :next-text='rightArrow'
          :container-class="'pagination'"
          :page-class="'page-item'"
          :page-link-class="'page-link'">
      </paginate>
    </div>
  </div>
</template>


<style scoped>

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>

<script>

import axios from 'axios'
import moment from 'moment'
import Item from './Item5'
import ContentLoader from '../../content-loader/ContentLoader2'

export default {
  props: {
    accountId: {
      type: String
    },
    pageSize: {
      type: Number
    },
    source: {
      type: String
    },
    seo: {
      type: Boolean
    },
    seoBaseUrl: {
      type: String
    }
  },
  name: 'App',
  data () {
    return {
      loaderCount: 4,
      finalPageSize: 3,
      pageNumber: 1,
      pageCount: 0,
      data: [],
      baseUrl: ''
    }
  },
  computed: {
    leftArrow() {
      return '<svg\n' +
          '  width="24"\n' +
          '  height="24"\n' +
          '  viewBox="0 0 24 24"\n' +
          '  fill="none"\n' +
          '  xmlns="http://www.w3.org/2000/svg"\n' +
          '>\n' +
          '  <path\n' +
          '    d="M16.2426 6.34317L14.8284 4.92896L7.75739 12L14.8285 19.0711L16.2427 17.6569L10.5858 12L16.2426 6.34317Z"\n' +
          '    fill="currentColor"\n' +
          '  />\n' +
          '</svg>';
    },
    rightArrow() {
      return '<svg\n' +
          '      width="24"\n' +
          '      height="24"\n' +
          '      viewBox="0 0 24 24"\n' +
          '      fill="none"\n' +
          '      xmlns="http://www.w3.org/2000/svg">\n' +
          '    <path\n' +
          '        d="M10.5858 6.34317L12 4.92896L19.0711 12L12 19.0711L10.5858 17.6569L16.2427 12L10.5858 6.34317Z"\n' +
          '        fill="currentColor"\n' +
          '    />\n' +
          '  </svg>';
    }
  },
  components: {
    Item,
    ContentLoader
  },
  methods: {
    pageChange (next) {
      this.data = []
      this.pageNumber = next
      this.getData()
    },
    async getData () {
      this.loaderCount = this.finalPageSize
      let passAccountId = this.accountId
      let response = await axios.get(`news/list?accountId=${passAccountId}&pageSize=${this.finalPageSize}&pageNumber=${this.pageNumber - 1}&source=${this.source}`)
      const res = response.data
      if (res && res.status) {
        for (let i = 0; i < res.data.length; i++) {
          const item = res.data[i]
          let link = `${this.baseUrl}?newsId=${item.id}`
          if (this.seo) {
            link = `${this.seoBaseUrl}/${item.titleId}#/?newsId=${item.id}`
          }
          this.data.push({
            link: link,
            time: moment(item.date, 'YYYY-MM-DDTHH:mm:ssZ').format('hh:mma'),//May 25, 2020 6:20am EDT
            date: moment(item.date, 'YYYY-MM-DDTHH:mm:ssZ').format('MMM DD, YYYY'),//May 25, 2020 6:20am EDT
            text: item.title
          })
        }
        const pagination = res.pagination
        const totalRecords = pagination.total
        this.pageCount = parseInt((totalRecords + this.finalPageSize - 1) / this.finalPageSize)
      }
      this.loaderCount = 0
    }
  },
  async beforeMount () {
    if (this.pageSize) {
      this.finalPageSize = this.pageSize
    }
    let response = await axios.get(`news/base-url?accountId=${this.accountId}`)
    const res = response.data
    if (res && res.status) {
      const val = res.value
      this.baseUrl = val.baseUrl
    }
  },
  mounted () {
    this.getData()
  }
}

</script>
