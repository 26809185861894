<template>
    <div class="col-12 col-lg-4 wow fadeInUp">
        <content-loader style="margin-top: 30px" width="600" height="200"
                        preserveAspectRatio="none"></content-loader>
    </div>
</template>

<script>
    import {ContentLoader} from 'vue-content-loader'

    export default {
        components: {
            ContentLoader
        },
    }
</script>
