<template>
  <div id="app">
    <Header/>
    <Home :account-id="accountId" :news-id="newsId" :news-details="newsDetails" :page-size="pageSize"
          :template-id="templateId" :source="source" :seo="seo" :seo-base-url="seoBaseUrl"
          :autoplay="autoplay"/>
    <Footer/>
  </div>
</template>


<script>

import Header from './Header'
import Footer from './Footer'
import Home from '../../views/Home.vue'

export default {
  props: {
    accountId: {
      type: String
    },
    newsId: {
      type: String
    },
    newsDetails: {
      type: Boolean
    },
    pageSize: {
      type: Number
    },
    templateId: {
      type: Number
    },
    source: {
      type: String
    },
    seo: {
      type: Boolean
    },
    seoBaseUrl: {
      type: String
    },
    autoplay: {
      type: Boolean
    },
  },
  components: {
    Header,
    Footer,
    Home
  }
}

</script>
