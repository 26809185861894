<template>
    <div class="col-12 col-lg-4 wow fadeInUp" data-wow-delay=".5s"
         style="visibility: visible; animation-delay: 0.5s; animation-name: fadeInUp;">
        <div class="news-block">
            <h6>{{data.time}}</h6>
            <p>{{data.text}}</p>
            <b-link @click="onclick(data.link)" class="learn-more text-capitalize mt-4">View Article <i
                    class="fas fa-chevron-right"></i></b-link>
        </div>
    </div>
</template>

<script>

    export default {
        props: {
            data: Object,
        },
        methods: {
            onclick(link) {
                this.$gtm.trackEvent({
                  event: 'View Press Release',
                  category: 'view',
                  action: 'click',
                  label: 'View Press Release',
                  value: this.data.text
                });
                window.open(link, "_self")
            }
        }
    }

</script>
