<template>
  <div>
    <div class="row">
      <Item :data="item" v-for="item in data" :key="item.id"/>
    </div>
  </div>
</template>


<style>
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #3253dc !important;
  border-color: #3253dc !important;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>

<script>

import axios from 'axios'
import moment from 'moment'
import Item from './Item3'

export default {
  props: {
    accountId: {
      type: String
    },
    pageSize: {
      type: Number
    },
    source: {
      type: String
    },
    seo: {
      type: Boolean
    },
    seoBaseUrl: {
      type: String
    }
  },
  name: 'App',
  data() {
    return {
      loaderCount: 4,
      finalPageSize: 3,
      pageNumber: 1,
      pageCount: 0,
      data: [],
      baseUrl: '',
      dateFormat: 'MMM DD, YYYY hh:mma',
    }
  },
  computed: {},
  components: {
    Item
  },
  methods: {
    pageChange(next) {
      this.data = [];
      this.pageNumber = next;
      this.getData();
    },
    async getData() {
      this.loaderCount = this.finalPageSize;
      let passAccountId = this.accountId;
      let response = await axios.get(`news/list?accountId=${passAccountId}&pageSize=${this.finalPageSize}&pageNumber=${this.pageNumber - 1}&source=${this.source}`);
      const res = response.data;
      if (res && res.status) {
        const settings = res.settings
        this.dateFormat = (settings && settings.pressReleaseTimeFormat) || this.dateFormat
        for (let i = 0; i < res.data.length; i++) {
          const item = res.data[i];
          let link = `${this.baseUrl}?newsId=${item.id}`;
          if (this.seo) {
            link = `${this.seoBaseUrl}/${item.titleId}#/?newsId=${item.id}`
          }
          this.data.push({
            link: link,
            time: moment(item.date, 'YYYY-MM-DDTHH:mm:ssZ').format(this.dateFormat),//May 25, 2020 6:20am EDT
            text: item.title
          });
        }
        const pagination = res.pagination;
        const totalRecords = pagination.total;
        this.pageCount = parseInt((totalRecords + this.finalPageSize - 1) / this.finalPageSize);
      }
      this.loaderCount = 0;
    }
  },
  async beforeMount() {
    if (this.pageSize) {
      this.finalPageSize = this.pageSize;
    }
    let response = await axios.get(`news/base-url?accountId=${this.accountId}`);
    const res = response.data;
    if (res && res.status) {
      const val = res.value;
      this.baseUrl = val.baseUrl;
    }
  },
  mounted() {
    this.getData();
  }
}

</script>
