<template>
    <div class="press-release-block">
        <h6>{{data.time}}</h6>
        <h4>
            <b-link @click="onclick(data.link)" title="">{{data.text}}</b-link>
        </h4>
    </div>
</template>

<script>

    export default {
        props: {
            data: Object,
        },
        methods: {
            onclick(link) {
                this.$gtm.trackEvent({
                  event: 'View Press Release',
                  category: 'view',
                  action: 'click',
                  label: 'View Press Release',
                  value: this.data.text
                });
                window.open(link, "_self")
            }
        }
    }

</script>
