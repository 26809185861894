<template>
  <div>
    <div class="row">
      <div class="col-12 mt-4">
        <div class="press-release-block">
          <h6>{{ date }}</h6>
          <h4>{{ title }}</h4>
          <span v-html="content">
                    </span>
        </div>
      </div>
    </div>
  </div>
</template>


<script>

import axios from 'axios'
import moment from 'moment'

export default {
  metaInfo() {
  },
  props: {
    accountId: {
      type: String
    },
    newsId: {
      type: String
    },
    source: {
      type: String
    }
  },
  data() {
    return {
      title: '',
      date: '',
      content: ''
    }
  },
  methods: {
    async getData() {
      let response = await axios.get(`news/details?accountId=${this.accountId}&newsId=${this.newsId}`);
      const res = response.data;
      if (res && res.status) {
        const details = res.value;
        this.title = details.title;
        this.date = moment(details.date, 'YYYY-MM-DDTHH:mm:ssZ').format('MMM DD, YYYY hh:mma');
        this.content = details.htmlDocument;
      }
    }
  },
  mounted() {
    this.getData();
  }
}

</script>
