<template>
    <content-loader style="margin-top: 30px" width="600" height="40"
                    preserveAspectRatio="none"></content-loader>
</template>

<script>
    import {ContentLoader} from 'vue-content-loader'

    export default {
        components: {
            ContentLoader
        },
    }
</script>
