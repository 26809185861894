<template>
  <div id="app">
    <Navigation :account-id="accountId" :news-id="newsId" :news-details="newsDetails" :page-size="pageSize"
                :template-id="templateId" :source="source" :seo="seo" :seo-base-url="seoBaseUrl"
                :autoplay="autoplay"/>
  </div>
</template>

<style lang="scss">
@import "assets/css/style.css";
@import "assets/css/style2.css";
@import "assets/css/animate.min.css";
@import "assets/css/font-awesome.min.css";
@import "assets/css/responsive.css";
</style>


<script>

import Navigation from './components/nav/Navigation'

export default {
  metaInfo: {
    changed() {
    }
  },
  props: {
    prop1: {},
    prop2: {},
    prop3: {},
    accountId: {
      type: String
    },
    newsId: {
      type: String
    },
    newsDetails: {
      type: Boolean
    },
    pageSize: {
      type: Number
    },
    templateId: {
      type: Number
    },
    source: {
      type: String
    },
    seo: {
      type: Boolean
    },
    seoBaseUrl: {
      type: String
    },
    autoplay: {
      type: Boolean
    },
  },
  components: {
    Navigation
  }
}

</script>
