<template>
  <div>
    <div class="">
      <div class="container">
        <div class="row">
          <div class="widget-carousel-block">
            <div class="widget-carousel">
              <VueSlickCarousel v-bind="settings" v-if='data.length' @init="progress"
                                @lazyLoad="progress" @afterChange="afterChange" ref="carousel">

                <ItemV2 :data="item" v-for="item in data" :key="item.id"/>

                <!--<template #prevArrow>
                    <i class="fas fa-long-arrow-alt-left"></i>
                </template>

                <template #nextArrow>
                    <div class="custom-arrow">
                        <i class="fas fa-long-arrow-alt-right"></i>
                    </div>
                </template>-->

              </VueSlickCarousel>
              <span style="margin-left: 40px" @click="showPrev"><i
                  class="fas fa-long-arrow-alt-left nav-arrow"></i></span>
              <span style="margin-left: 5px; margin-right: 5px"></span>
              <span @click="showNext"><i class="fas fa-long-arrow-alt-right nav-arrow"></i></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>

.slick-slide {
  margin: 5px;
}

.nav-arrow {
  font-size: 32px;
}

.nav-arrow:hover {
  cursor: pointer;
}

</style>

<script>

import ItemV2 from "./ItemV2";
import axios from 'axios'
import moment from 'moment'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'

export default {

  props: {
    accountId: {
      type: String
    },
    pageSize: {
      type: Number
    },
    source: {
      type: String
    },
    seo: {
      type: Boolean
    },
    seoBaseUrl: {
      type: String
    }
  },
  data() {
    return {
      settings: {
        arrows: false,
        dots: false,
        centerMode: false,
        infinite: false,
        slidesToShow: 3,
        accessibility: false,
        lazyLoad: "ondemand",
        adaptiveHeight: true
      },
      currentSlide: 0,
      arrowOption: {},
      loaderCount: 4,
      finalPageSize: 10,
      pageNumber: 1,
      pageCount: 0,
      data: [],
      baseUrl: '',
      dateFormat: 'MMM DD, YYYY hh:mma',
    }
  },
  computed: {},
  components: {
    VueSlickCarousel,
    ItemV2
  },
  methods: {
    async getData() {
      this.loaderCount = this.finalPageSize;
      let passAccountId = this.accountId;
      let response = await axios.get(`news/list?accountId=${passAccountId}&pageSize=${this.finalPageSize}&pageNumber=${this.pageNumber - 1}&source=${this.source}`);
      const res = response.data;
      if (res && res.status) {
        const settings = res.settings
        this.dateFormat = (settings && settings.pressReleaseTimeFormat) || this.dateFormat
        for (let i = 0; i < res.data.length; i++) {
          const item = res.data[i];
          let link = `${this.baseUrl}?newsId=${item.id}`;
          if (this.seo) {
            link = `${this.seoBaseUrl}/${item.titleId}#/?newsId=${item.id}`
          }
          this.data.push({
            link: link,
            time: moment(item.date, 'YYYY-MM-DDTHH:mm:ssZ').format(this.dateFormat),//May 25, 2020 6:20am EDT
            text: item.title
          });
        }
        const pagination = res.pagination;
        const totalRecords = pagination.total;
        this.pageCount = parseInt((totalRecords + this.finalPageSize - 1) / this.finalPageSize);
      }
      this.loaderCount = 0;
    },
    progress(v) {
      if (v && v.length > 0) {
        const index = v[v.length - 1] + 1;
        if (index === this.data.length) {
          console.log('reached end');
          this.pageNumber = this.pageNumber + 1;
          this.getData();
        }
      }
    },
    showNext() {
      this.$refs.carousel.next()
    },
    showPrev() {
      if (this.currentSlide > 0)
        this.$refs.carousel.prev()
    },
    afterChange(slideIndex) {
      this.currentSlide = slideIndex;
    }
  },
  async beforeMount() {
    if (this.pageSize) {
      this.finalPageSize = this.pageSize;
    }
    let response = await axios.get(`news/base-url?accountId=${this.accountId}`);
    const res = response.data;
    if (res && res.status) {
      const val = res.value;
      this.baseUrl = val.baseUrl;
    }
    await this.getData();
  },
  mounted() {
  }
}

</script>
